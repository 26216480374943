import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/Seo/Seo";
import {WhiteSubheading} from "../components/common/headings";
import {Link} from "gatsby";
import "../styles/privacy.css"

const Privacy = () => (
    <Layout>
        <Seo
            title="Privacy Policy"
            description="Mafiaverse Privacy Policy"
            pageType="WebPage"
            ogType="website"
        />
        <div className="content">
            <div className="privacy-policy-container has-text-white py-6">
                <div className="container px-2">
                    <h1 className="title is-underlined has-text-centered mafia-heading">MAFIAVERSE PRIVACY POLICY</h1>
                    <p>At Mafiaverse("We", "Us", "Our"), accessible from <Link
                        to="https://mafiaverse.io">https://mafiaverse.io</Link>, one of our main
                        priorities is the privacy of our visitors. This Privacy Policy document contains types of
                        information that is collected and recorded by Mafiaverse and how we use it.
                    </p>
                    <p>
                        If you have additional questions or require more information about our Privacy Policy, do not
                        hesitate to contact us.
                    </p>
                    <p>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our
                        website with regards to the information that they share and/or collect in Mafiaverse. This
                        policy is not applicable to any information collected offline or via channels other than this
                        website.
                    </p>
                    <WhiteSubheading>Consent</WhiteSubheading>
                    <p>
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                    </p>
                    <WhiteSubheading>Information we collect</WhiteSubheading>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to
                        provide it, will be made clear to you at the point we ask you to provide your personal
                        information.
                    </p>
                    <p>
                        If you contact us directly, we may receive additional information about you such as your name,
                        email address, phone number, the contents of the message and/or attachments you may send us, and
                        any other information you may choose to provide.
                    </p>
                    <p>Depending on how you choose to use our Services, you may provide us with the following
                        information:</p>
                    <ul>
                        <li>Things like your first and last name, username or handle, third party service ID, email
                            address, phone number, home address or shipping address, and other contact information.
                        </li>
                        <li>Your photo, avatar or other image.</li>
                        <li>Your date of birth, and gender.</li>
                        <li>Purchase or payment information.</li>
                        <li>
                            Other information such as chat records, friends' names and contact information, and
                            non-precise location information you choose to share (for example, when you connect with our
                            Services through social features).
                        </li>
                    </ul>
                    <WhiteSubheading>How we use your information</WhiteSubheading>
                    <p>We use the information we collect in various ways, including to:</p>
                    <ul>
                        <li>Provide, operate, and maintain our website.</li>
                        <li>Improve, personalise, and expand our website.</li>
                        <li>Understand and analyse how you use our website.</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>
                            Communicate with you, either directly or through one of our partners, including for customer
                            service, to provide you with updates and other information relating to the website, and for
                            marketing and promotional purposes.
                        </li>
                        <li>Send you emails.</li>
                        <li>Find and prevent fraud.</li>
                    </ul>
                    <WhiteSubheading>Log Files</WhiteSubheading>
                    <p>Mafiaverse follows a standard procedure of using log files. These files log visitors when they
                        visit websites. All hosting companies do this as part of hosting services' analytics. The
                        information collected by log files include internet protocol (IP) addresses, browser type,
                        Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                        number of clicks and heatmaps. These are not linked to any information that is personally
                        identifiable. The purpose of the information is for analysing trends, administering the site,
                        tracking users' movement on the website, and gathering demographic information.
                    </p>
                    <WhiteSubheading>Third Party Privacy Policies</WhiteSubheading>
                    <p>Our website may use or link to external websites and services that are not operated by us. Please
                        be aware that we have no control over the content and practices of these sites, and cannot
                        accept responsibility or liability for their respective privacy policies.
                    </p>
                    <p>
                        Mafiaverse's Privacy Policy does not apply to other advertisers, websites or services. Thus, we
                        are advising you to consult the respective Privacy Policies of these third-party services for
                        more detailed information. It may include their practices and instructions about how to opt-out
                        of certain options.
                    </p>
                    <p>
                        You can choose to disable cookies through your individual browser options. To know more detailed
                        information about cookie management with specific web browsers, it can be found at the browsers'
                        respective websites.
                    </p>
                    <WhiteSubheading>CCPA Privacy Rights (Do Not Sell My Personal Information)</WhiteSubheading>
                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                    <p>Request that a business that collects a consumer's personal data disclose the categories and
                        specific pieces of personal data that a business has collected about consumers.
                    </p>
                    <p>
                        Request that a business delete any personal data about the consumer that a business has
                        collected.
                    </p>
                    <p>
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal
                        data.
                    </p>
                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>
                    <WhiteSubheading>GDPR Data Protection Rights</WhiteSubheading>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user
                        is entitled to the following:
                    </p>
                    <p>
                        The right to access – You have the right to request copies of your personal data. We may charge
                        you a small fee for this service.
                    </p>
                    <p>
                        The right to rectification – You have the right to request that we correct any information you
                        believe is inaccurate. You also have the right to request that we complete the information you
                        believe is incomplete.
                    </p>
                    <p>
                        The right to erasure – You have the right to request that we erase your personal data, under
                        certain conditions.
                    </p>
                    <p>
                        The right to restrict processing – You have the right to request that we restrict the processing
                        of your personal data, under certain conditions.
                    </p>
                    <p>
                        The right to object to processing – You have the right to object to our processing of your
                        personal data, under certain conditions.
                    </p>
                    <p>
                        The right to data portability – You have the right to request that we transfer the data that we
                        have collected to another organisation, or directly to you, under certain conditions.
                    </p>
                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>
                    <WhiteSubheading>Children's Information</WhiteSubheading>
                    <p>Another part of our priority is adding protection for children while using the internet. We
                        encourage parents and guardians to observe, participate in, and/or monitor and guide their
                        online activity.
                    </p>
                    <p>
                        Mafiaverse does not knowingly collect any Personal Identifiable Information from children under
                        the age of 13. If you think that your child provided this kind of information on our website, we
                        strongly encourage you to contact us immediately and we will do our best efforts to promptly
                        remove such information from our records.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default Privacy;