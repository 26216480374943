import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

interface TwitterProps {
  username: string;
  title: string;
  description: string;
  image: string;
}

const Twitter = (props: TwitterProps) => (
  <Helmet>
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.description} />
    <meta name="twitter:site" content={props.username} />
    <meta name="twitter:creator" content={props.username} />
    <meta name="twitter:image" content={props.image} />
    <meta name="twitter:image:alt" content={props.description} />
  </Helmet>
);

export default Twitter;
