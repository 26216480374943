import React from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from '@reach/router';
import {useStaticQuery, graphql} from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

interface SeoProps {
    title?: string;
    description?: string;
    image?: string;
    ogType: string;
    keywords?: Array<string>;
    pageType: string;
    articlePublishDate?: string;
}

const Seo = (props: SeoProps) => {
    const {pathname} = useLocation();
    const {site} = useStaticQuery(query);
    const {
        name,
        title,
        titleTemplate,
        description,
        siteUrl,
        datePublished,
        logo,
        siteLang,
        openGraphLang,
        email,
        twitterHandle,
        githubOrg,
        instagramPage
    } = site.siteMetadata;
    const seo = {
        title: props.title ?? title,
        desc: props.description ?? description,
        image: props.image ?? logo,
        url: `${siteUrl}${pathname}`,
    };
    const canonical = pathname ? `${siteUrl}${pathname}` : null;
    const currentYear = new Date().getFullYear();
    const schemaOrganization = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name,
        url: siteUrl,
        description,
        email,
        logo: {
            '@type': 'ImageObject',
            url: logo,
            height: '60',
            width: '600',
        },
        sameAs: [
            `https://twitter.com/${twitterHandle}`,
            `https://github.com/${githubOrg}`,
            `https://instagram.com/${instagramPage}`,
        ],
        contactPoint: [
            {
                '@type': 'ContactPoint',
                email: email,
                contactType: 'Contact and Feedback',
            },
        ],
    };

    const schemaWebPage = {
        '@context': 'http://schema.org',
        '@type': props.pageType,
        url: siteUrl,
        headline: description,
        inLanguage: siteLang,
        mainEntityOfPage: siteUrl,
        description: seo.desc,
        name,
        author: {
            '@type': 'Organization',
            name,
        },
        copyrightHolder: {
            '@type': 'Organization',
            name,
        },
        copyrightYear: `${currentYear}`,
        creator: {
            '@type': 'Organization',
            name,
        },
        publisher: {
            '@type': 'Organization',
            name,
        },
        datePublished,
        dateModified: site.buildTime,
        image: {
            '@type': 'ImageObject',
            url: logo,
        },
    };

    return (
        <>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
                <html lang={siteLang}/>
                <meta name="description" content={seo.desc}/>
                <meta name="image" content={seo.image}/>
                {canonical && <link rel="canonical" href={canonical}/>}
                {props.keywords && (
                    <meta name="keywords" content={props.keywords.join(',')}/>
                )}
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrganization)}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify(schemaWebPage)}
                </script>
            </Helmet>
            <Facebook
                siteName={name}
                title={seo.title}
                desc={seo.desc}
                image={seo.image}
                type={props.ogType}
                url={seo.url}
                locale={openGraphLang}
            />
            <Twitter
                username={twitterHandle}
                title={seo.title}
                description={seo.desc}
                image={seo.image}
            />
        </>
    );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        name
        title
        email
        titleTemplate
        description
        siteUrl
        datePublished
        logo
        siteLang
        openGraphLang
        twitterHandle
        githubOrg
        instagramPage
      }
    }
  }
`;
