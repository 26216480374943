import React from 'react';
import { Helmet } from 'react-helmet';

interface FacebookProps {
  siteName: string;
  url: string;
  locale: string;
  type: string;
  title: string;
  desc: string;
  image: string;
  fbAppId?: string;
}

const Facebook = (props: FacebookProps) => (
  <Helmet>
    <meta property="og:site_name" content={props.siteName} />
    <meta property="og:url" content={props.url} />
    <meta property="og:title" content={props.title} />
    <meta property="og:description" content={props.desc} />
    <meta property="og:image" content={props.image} />
    <meta property="og:locale" content={props.locale} />
    <meta property="og:type" content={props.type} />
    <meta property="og:image:alt" content={props.desc} />
    {props.fbAppId && <meta property="fb:app_id" content={props.fbAppId} />}
  </Helmet>
);

export default Facebook;
